import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useFormik} from 'formik';
import * as yup from "yup";
import {createQuickRegistration} from "../../services/admin/AdminService";

const QuickRegistration = () => {
  const [registrationMessage, setRegistrationMessage] = useState('');
  const navigate = useNavigate();

  const validationSchema = yup.object({
    nameFirst: yup
      .string('Enter a first name')
      .required('First name is required'),
    nameLast: yup
      .string('Enter a last name')
      .required('Last name is required'),
    email: yup
      .string('Enter an email')
      .email('Enter a valid email')
      .required('Email is required'),
    phone: yup
    .string('Enter a phone number')
      .min(10, 'Please include your area code')
    .required('Phone number is required')
  });

  const formik = useFormik({
    initialValues: {
      nameFirst: '',
      nameLast: '',
      email: '',
      phone: '',
      childFirstName: '',
      childLastName: '',
      notes: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      const { success, message, data } = await createQuickRegistration(values);
      if(success) {
        // navigate back to /admin
        navigate('/admin');
      } else {
        setRegistrationMessage(message);
        setTimeout(() => setRegistrationMessage(''), 5000);
        // show error
      }
    }
  })

  return (
    <Container component="main" maxWidth="md">
      <Box>
        <Typography component="h1" variant="h4" align="center">
          Quick Registration
        </Typography>
        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="nameFirst"
                required
                fullWidth
                id="nameFirst"
                label="First Name"
                autoFocus
                value={formik.values.nameFirst}
                onChange={formik.handleChange}
                error={formik.touched.nameFirst && Boolean(formik.errors.nameFirst)}
                helperText={formik.touched.nameFirst && formik.errors.nameFirst}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="nameLast"
                label="Last Name"
                name="nameLast"
                value={formik.values.nameLast}
                onChange={formik.handleChange}
                error={formik.touched.nameLast && Boolean(formik.errors.nameLast)}
                helperText={formik.touched.nameLast && formik.errors.nameLast}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="phone"
                label="Phone"
                type="phone"
                id="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="childFirstName"
                label="Child's First Name"
                id="childFirstName"
                value={formik.values.childFirstName}
                onChange={formik.handleChange}
                error={formik.touched.childFirstName && Boolean(formik.errors.childFirstName)}
                helperText={formik.touched.childFirstName && formik.errors.childFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="childLastName"
                label="Child's Last Name"
                id="childLastName"
                value={formik.values.childLastName}
                onChange={formik.handleChange}
                error={formik.touched.childLastName && Boolean(formik.errors.childLastName)}
                helperText={formik.touched.childLastName && formik.errors.childLastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="notes"
                label="Narrative -- notes"
                multiline
                id="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
              />
            </Grid>
            {registrationMessage && (<Grid item xs={12}>
              <Typography variant="subtitle" color={"red"}>
                {registrationMessage}
              </Typography>
            </Grid>)}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default QuickRegistration;