import {useEffect, useState} from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import AdminContext from "../../services/admin/AdminContext";
import {
  getAllAssessments,
  getAllParents,
} from "../../services/admin/AdminService";
import { meService } from "../../services/auth/AuthService";
import Container from "@mui/material/Container";
import LogoHeader from "../../components/LogoHeader";

const AdminLayout = () => {
  const navigate = useNavigate();

  const [adminData, setAdminData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parents, setParents] = useState([]);
  const [assessments, setAssessments] = useState([]);
  useEffect(() => {
    const getParents = async () => {
      setIsLoading(true);
      const { success, data } = await getAllParents();
      console.log("getting parents");

      if (success) {
        setParents(data);
      }
    };
    getParents();
    setIsLoading(false);
    const getAssessments = async () => {
      setIsLoading(true);
      const { success, data } = await getAllAssessments();
      if (success) {
        setAssessments(data);
      }
    };
    getAssessments();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const attemptLogin = async () => {
      const bearerToken = localStorage.getItem("bearer");
      if (bearerToken) {
        setIsLoading(true);

        const { success, data } = await meService(bearerToken);
        setIsLoading(false);

        if (success) {
          setAdminData(data);
        }
      } else {
        navigate("/login");
      }
    };
    attemptLogin();
  }, []);

  useEffect(() => {
    if (adminData && !adminData.isAdmin) navigate("/");
  }, [adminData]);

  return (
    <AdminContext.Provider
      value={{
        assessments,
        parents,
        isAdmin: adminData.isAdmin,
        isLoading,
        adminData,
      }}
    >
      <Container component="main" maxWidth={"sm"}>
        <LogoHeader />
      </Container>
      <Outlet />
    </AdminContext.Provider>
  );
};

export default AdminLayout;