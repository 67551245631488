import { useFormik } from 'formik';
import {Box, Button, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useContext, useState} from "react";
import AdminContext from "../../services/admin/AdminContext";
import {createAssessment} from "../../services/admin/AdminService";
import {useNavigate, useParams} from "react-router-dom";

const AddAssessment = () => {
  const { assessments } = useContext(AdminContext);
  const { parentId, studentId } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      textAssessment: ''
    },
    onSubmit: async (values) => {
      const token = localStorage.getItem('bearer');
      const { textAssessment } = values;
      const { success } = await createAssessment(token, parentId, studentId, textAssessment.id, textAssessment.name);
      if(success) {
        navigate(`/admin/family/${parentId}/`);
      }
    }
  })

  return (
    <Container component="main" maxWidth="md">
      <Box>
        <Typography component="h1" variant="h4" align="center">
          Add Assessment
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                select
                id="textAssessment"
                label="Which Assessment?"
                name="textAssessment"
                onChange={formik.handleChange}
                value={formik.values.textAssessment}
              >
                {assessments &&
                  assessments.map((lesson) => (
                    <MenuItem key={lesson.id} value={lesson}>
                      {lesson.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default AddAssessment;