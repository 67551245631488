import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AdminContext from "../../services/admin/AdminContext";
import { useContext, useEffect, useState } from "react";

const Admin = () => {
  const { isLoading, parents } = useContext(AdminContext);
  const [filteredFamilies, setFilteredFamilies] = useState(parents);
  let navigate = useNavigate();

  useEffect(() => {
    setFilteredFamilies(parents);
  }, [parents]);

  function goToQuickReg(event) {
    event.preventDefault();
    navigate("/admin/quick-registration");
  }

  function goToFamily(event, parentID) {
    event.preventDefault();
    navigate(`/admin/family/${parentID}`);
  }

  function displayFamilies(families) {
    return families.map((family) => {
      return (
        family?.parentID && (
          <Button
            sx={{ px: "10px", my: "10px", borderColor: "rgb(118, 118, 118)" }}
            variant="outlined"
            size="large"
            fullWidth
            onClick={(e) => goToFamily(e, family.parentID)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: "rgb(118, 118, 118)",
              }}
            >
              {family.nameLast}, {family.nameFirst}
              <ArrowForwardIosOutlinedIcon />
            </Box>
          </Button>
        )
      );
    });
  }

  function filterFamilies(event) {
    const { value } = event.target;

    if (value !== "") {
      const matchingFamilies = parents.filter(
        (family) =>
          family.nameFirst.toLowerCase().includes(value.toLowerCase()) ||
          family.nameLast.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredFamilies(matchingFamilies);
    } else {
      setFilteredFamilies(parents);
    }
  }

  const signOut = () => {
    localStorage.removeItem("bearer");
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="md">
      <Box display={"flex"} mb={"1rem"}>
        <Box flex={1} />
        <Box flex={1} justifyContent={"center"}>
          <Typography component="h1" variant="h4" align="center">
            Admin Panel
          </Typography>
        </Box>
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            variant="text"
            size="large"
            endIcon={<AddCircleRoundedIcon />}
            onClick={goToQuickReg}
          >
            Quick Reg
          </Button>
          <Button variant="text" onClick={signOut} sx={{ alignSelf: "end" }}>
            Sign out
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          mb: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={filterFamilies}
          sx={{ width: "50%" }}
        />
      </Box>
      <Paper elevation={4} sx={{ padding: "10px" }}>
        {isLoading ? <CircularProgress /> : displayFamilies(filteredFamilies)}
      </Paper>
    </Container>
  );
};

export default Admin;
