import axios from 'axios';
import { baseUrl } from '../util';

export const loginService = async (creds) => {
  try {
    const loginResponse = await axios({
      method: 'post',
      url: `${baseUrl}/auth/login`,
      data: JSON.stringify(creds),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(loginResponse.status === 200) {
      // if (creds.remember)
      localStorage.setItem("bearer", loginResponse.data.token);
      return {
        success: true,
        message: "Success",
        data: loginResponse.data.user,
      };
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    // bad password
    if (error.response.status === 401 || error.response.status === 404) {
      return {
        success: false,
        message: 'Incorrect username or password',
        data: null
      };
    }
    // any other status code
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: error
    }
  }
}

export const meService = async (token) => {
  try {
    const meResponse = await axios({
      method: 'get',
      url: `${baseUrl}/me`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if(meResponse.status === 200) {
      return {
        success: true,
        message: 'Success',
        data: meResponse.data
      };
    } else {
      return {
        success: false,
        message: 'Token expired',
        data: null
      }
    }
  }
  catch(error) {
    if(error.response.status === 404 || error.response.status === 422) {
      // delete token in local storage
      if(localStorage.getItem('bearer')) {
        localStorage.removeItem('bearer')
      }
      // route to login page
      return {
        success: false,
        message: 'Token expired',
        data: error
      }
    }
  }
}