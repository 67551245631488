import {
  Routes,
  Route,
} from 'react-router-dom';
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import Parent from "./pages/dashboard/Parent";
import Admin from "./pages/admin/Admin";
import Family from "./pages/admin/Family";
import Login from "./pages/auth/Login";
import Enroll from "./pages/Enroll";
import QuickRegistration from "./pages/admin/QuickRegistration";
import AdminLayout from "./pages/admin/AdminLayout";
import AddAssessment from "./pages/admin/AddAssessment";
import Scheduler from "./pages/dashboard/Scheduler";

function App() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Parent />}/>
        <Route path="/scheduler/:assessmentId" element={<Scheduler />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="" element={<Admin />}/>
        <Route path="quick-registration" element={<QuickRegistration />}/>
        <Route path="family/:parentId" element={<Family />}/>
        <Route path="family/:parentId/student/:studentId/add-assessment" element={<AddAssessment />}/>
      </Route>
      <Route path="/login" element={<Login/>}/>
      <Route path="/enroll/:enrollToken" element={<Enroll/>}/>
    </Routes>
  );
}

export default App;
