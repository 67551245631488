import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Container from "@mui/material/Container";
import {Box, Button, CircularProgress, Grid, Modal, TextField, Typography} from "@mui/material";
import AdminContext from "../../services/admin/AdminContext";
import {
  createStudentOnParent,
  getFamilyById,
} from '../../services/admin/AdminService';
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ChildContainer from "../../components/ChildContainer";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ffffff',
  border: '2px solid #000',
  borderRadius: '15px',
  boxShadow: 24,
  p: 4
};

const Family = () => {
  const { parentId } = useParams();
  const { parents, isAdmin } = useContext(AdminContext);
  const [family, setFamily] = useState();
  const [fullFamilyData, setFullFamilyData] = useState();
  const [students, setStudents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFamily(parents.find((fam) => fam.parentID === parseInt(parentId)));
  }, [parents, parentId]);

  useEffect(() => {
    const getSelectedFamily = async (familyId) => {
      setIsLoading(true);
      const { success, data } = await getFamilyById(familyId);
      if (success) {
        setFullFamilyData(data);
        setIsLoading(false);
      }
    };
    if (family) getSelectedFamily(family.id);
  }, [family]);

  useEffect(() => {
    const twStudents = fullFamilyData?.teachworksDetails?.students?.map(
      (st) => {
        st.assessments = [];
        fullFamilyData?.assessments?.forEach((assessment) => {
          if (assessment.studentID === st.id) {
            if (assessment.tempID) {
              st.assessments.push(assessment);
            } else {
              fullFamilyData?.teachworksDetails?.assessments?.find(
                (twAssessment) => {
                  if (twAssessment.id === assessment.lessonID)
                    st.assessments.push(twAssessment);
                }
              );
            }
          }
        });
        return st;
      }
    );

    setStudents(twStudents);
  }, [fullFamilyData]);

  const openAddStudentModal = () => setModalOpen(true);
  const closeAddStudentModal = () => setModalOpen(false);

  const buildStudentCards = () => {
    if (!students?.length) {
      return <ChildContainer isAdminView={isAdmin} student={null} />;
    }
    return students.map((student) => (
      <ChildContainer
        key={student.id}
        isAdminView={isAdmin}
        student={student}
      />
    ));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const firstName = event.target[0].value;
    const lastName = event.target[2].value;

    const { success, message } = await createStudentOnParent(
      family?.id,
      firstName,
      lastName
    );
    if (success) closeAddStudentModal();
    else setError(message);
  };

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box sx={{ display: "flex", mb: "2rem" }}>
          <Box flex={1} />
          <Typography flex={3} component="h1" variant="h4" align="center">
            {family?.nameFirst} {family?.nameLast}'s Dashboard
          </Typography>
          <Box
            flex={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              size="large"
              endIcon={<AddCircleRoundedIcon />}
              onClick={openAddStudentModal}
            >
              Add student
            </Button>
          </Box>
        </Box>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          buildStudentCards()
        )}
      </Container>
      <Modal
        open={modalOpen}
        onClose={closeAddStudentModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add a Student
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create
            </Button>
            {error && <div style={{ color: "#d32f2f" }}>{error}</div>}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Family;