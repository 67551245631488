import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { loginService } from "../../services/auth/AuthService";
import LogoHeader from "../../components/LogoHeader";

const Login = ({setIsLoggedIn, setUserData, setShouldShowDashboard}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // remember: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikBag) => {
      const { data, success, message } = await loginService(values);
      if (success) {
        setIsLoggedIn(success);
        setUserData(data);
        setShouldShowDashboard(true);
      } else {
        setErrorMessage(message);
        formikBag.resetForm();
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <LogoHeader />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          {errorMessage && <Box sx={{ color: "red" }}>{errorMessage}</Box>}
          {/* <FormControlLabel
              control={<Checkbox name="remember" checked={formik.values.remember} onChange={formik.handleChange} color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;