import { Box, Button, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";

function AssessmentCard({ assessment, isAdminView }) {
    let statusElement;
    const navigate = useNavigate();

    const goToScheduler = (event) => {
        event.preventDefault();
        navigate(`/scheduler/${assessment.tempID}`);
    }
    if(!isAdminView && !assessment.status) {
        statusElement = 
        <Button onClick={goToScheduler}>
            Schedule Assessment Overview
        </Button>
    }
    else {
        statusElement = <Typography align="center">{assessment.status || "N/A"}</Typography>
    }

    let dateElement;
    if(!isAdminView && !assessment.from_datetime) {
      dateElement = (
        <Button onClick={goToScheduler} sx={{ py: "0" }}>
          Schedule Assessment
        </Button>
      );
    } else {
      dateElement = (
        <Typography align="center">
          {assessment.from_datetime
            ? format(new Date(assessment.from_datetime), "M/d/yyyy h:mm aaa")
            : "N/A"}
        </Typography>
      );
    }

    return(
      <Box py={"0.5rem"}>
        <Card variant="outlined" sx={{ borderColor: '#000000'}}>
            <CardContent>
                <Grid container direction="column">
                    <Grid item xs container direction="column" sx={{ pb: "1rem" }}>
                        <Typography sx={{ fontWeight: 'bold' }} align="center">Assessment Type</Typography>
                        <Typography align="center">{assessment.service_name ?? assessment.assessmentType}</Typography>
                    </Grid>
                    <Grid item xs container direction="row">
                        <Grid item xs container direction="column" justifyContent={"end"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: 'bold' }}  align="center">Status</Typography>
                            {statusElement}
                        </Grid>
                        <Grid item xs container direction="column" justifyContent={"end"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: 'bold' }}  align="center">Assessment Date</Typography>
                            {dateElement}
                        </Grid>
                        <Grid item xs container direction="column" justifyContent={"end"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: 'bold' }}  align="center">Teacher</Typography>
                            <Typography align="center">{assessment.employee_name || "N/A"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
      </Box>
    )
}

export default AssessmentCard;