import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import DashboardContext from "../../services/dashboard/DashboardContext";
import Login from "../auth/Login";
import {meService} from "../../services/auth/AuthService";
import Container from "@mui/material/Container";
import LogoHeader from "../../components/LogoHeader";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState();
  const [shouldShowDashboard, setShouldShowDashboard] = useState(false);

  // auto login with /me endpoint and saved bearer token
  useEffect(() => {
    const attemptLogin = async () => {
      const bearerToken = localStorage.getItem('bearer');
      if(bearerToken) {
        setIsLoggingIn(true);
        setShouldShowDashboard(true);
        const { success, data } = await meService(bearerToken);
        if (success) {
          setUserData(data);
          setIsAdmin(data.isAdmin);
          setIsLoggingIn(false);
          setIsLoggedIn(true);
        } else {
          setShouldShowDashboard(false);
        }
      } else {
        navigate("/login");
      }
    }
    attemptLogin();
  }, []);

  if (userData && userData.isAdmin) {
    navigate("/admin");
  }

  if(shouldShowDashboard) {
    return (
      <DashboardContext.Provider value={{isAdmin, isLoggedIn, isLoggingIn, setShouldShowDashboard, userData}}>
        <Container component="main" maxWidth={'sm'}>
          <LogoHeader />
        </Container>
        <Outlet/>
      </DashboardContext.Provider>
    );
  }
  return <Login setIsLoggedIn={setIsLoggedIn} setUserData={setUserData} setShouldShowDashboard={setShouldShowDashboard} />
}

export default DashboardLayout;