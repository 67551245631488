import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import {
  differenceInMinutes,
  addHours,
  addMinutes,
  getDay,
  isEqual,
  isSameDay,
  isBefore,
  isAfter, format
} from 'date-fns';
import {Box, Button, Card, CardContent, CircularProgress, Container, Grid, Typography} from '@mui/material';
import {createAssessment, getAssessmentAvailability} from "../../services/dashboard/DashboardService";

const Scheduler = () => {
  const {assessmentId} = useParams();
  const [shouldShowLoading, setShouldShowLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [assessmentAvailabilities, setAssessmentAvailabilities] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const scheduleAssessment = async (start, employeeId) => {
    const {success, message} = await createAssessment(assessmentId, start, employeeId);
    if(success) {
      navigate('/');
    } else {
      setError(message);
    }
  }

  const generateAvailabilityCard = ({assessmentDuration, employeeId, start}) => {
      return (
        <Card>
          <CardContent>
            <Grid item container>
              <Grid item xs container direction="column">
                <Typography>Start time: {format(start, 'h:mm bbb')}</Typography>
                <Typography>Duration: {assessmentDuration} minutes</Typography>
              </Grid>
              <Grid item container direction="column">
                <Button onClick={() => scheduleAssessment(start, employeeId)} variant="outlined">Schedule!</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
  }

  const getAvailabilities = (dateToFindAvailabilities) => {
    const {assessmentLength, coreAvailabilities, existingLessons} = assessmentAvailabilities;
    const availableStaff = [];
    const availableAssessmentSlots = [];
    const matchingExistingLessons = [];
    const SCHEDULING_SLOT_GAP = 30;

    // see if any lessons exist that overlap the available duration
    // loop through by 30 minute intervals until the duration exceeds the available time

    // determine assessment duration
    const assessmentDuration = (assessmentLength?.hours * 60) + assessmentLength?.minutes;

    // determine availability on the selected date
    const dayNumber = getDay(dateToFindAvailabilities);
    const staff = coreAvailabilities?.[dayNumber];

    // find availabilities that are long enough for the duration of the lesson
    if(staff.length) {
      staff.forEach(s => {
        // parse start time
        const staffStart = s.startTime.split(":");
        const staffStartTimeHours = staffStart[0];
        const staffStartTimeMinutes = staffStart[1];
        const staffStartTime = addHours(addMinutes(dateToFindAvailabilities, staffStartTimeMinutes), staffStartTimeHours);
        // parse end time
        const staffEnd = s.endTime.split(":");
        const staffEndTimeHours = staffEnd[0];
        const staffEndTimeMinutes = staffEnd[1];
        const staffEndTime = addHours(addMinutes(dateToFindAvailabilities, staffEndTimeMinutes), staffEndTimeHours);
        // find availability in minutes
        const staffAvailableMinutes = differenceInMinutes(staffEndTime, staffStartTime);

        if(staffAvailableMinutes >= assessmentDuration) {
          availableStaff.push({
            employeeId: s.employeeId,
            end: staffEndTime,
            start: staffStartTime,
            availableMinutes: staffAvailableMinutes
          });
        }
      })
    }

    existingLessons?.forEach(lesson => {
      const lessonStart = new Date(lesson.fromDate + 'T' + lesson.fromTime);
      const lessonEnd = new Date(lesson.toDate + 'T' + lesson.toTime);
      const lessonDuration = differenceInMinutes(lessonEnd, lessonStart);
      if(isSameDay(dateToFindAvailabilities, lessonStart)) {
        matchingExistingLessons.push({
          start: lessonStart,
          end: lessonEnd,
          duration: lessonDuration
        });
      }
    })

    if(availableStaff.length) {
      availableStaff.forEach(staff => {
        if(matchingExistingLessons.length) {
          matchingExistingLessons.forEach(lesson => {
            // first, check to ensure the existing lesson's timeframe overlaps
            // with the staff member's
            if((isAfter(lesson.start, staff.start) || isEqual(lesson.start, staff.start))
            && (isEqual(lesson.end, staff.end) || isAfter(staff.end, lesson.end))) {
              // get duration available before lesson
              const timeAvailableBeforeLesson = differenceInMinutes(lesson.start, staff.start);
              if (timeAvailableBeforeLesson >= assessmentDuration) {
                availableAssessmentSlots.push({
                  start: staff.start,
                  employeeId: staff.employeeId,
                  assessmentDuration
                });
              }

              // determine if additional time slots at SCHEDULING_SLOT_GAP intervals
              // are available before the previously scheduled lesson
              let startTime = addMinutes(staff.start, SCHEDULING_SLOT_GAP);
              while ((Math.abs(differenceInMinutes(startTime, lesson.start)) - assessmentDuration) >= SCHEDULING_SLOT_GAP) {
                availableAssessmentSlots.push({
                  start: startTime,
                  employeeId: staff.employeeId,
                  assessmentDuration
                });
                startTime = addMinutes(startTime, SCHEDULING_SLOT_GAP);
              }
              // determine if additional time slots at SCHEDULING_SLOT_GAP intervals
              // are available after the previously scheduled lesson
              let afterLessonStartTime = lesson.end;
              while ((Math.abs(differenceInMinutes(afterLessonStartTime, staff.end)) - assessmentDuration) >= SCHEDULING_SLOT_GAP) {
                availableAssessmentSlots.push({
                  start:afterLessonStartTime,
                  employeeId: staff.employeeId,
                  assessmentDuration
                });
                afterLessonStartTime = addMinutes(afterLessonStartTime, SCHEDULING_SLOT_GAP);
              }
            } else {
              // the existing lesson is not scheduled within the current staff members
              // availability window
              let beginningAvailable = staff.start;
              let assessmentEnd = addMinutes(beginningAvailable, assessmentDuration);
              while(isBefore(assessmentEnd, staff.end) || isEqual(assessmentEnd, staff.end)) {
                availableAssessmentSlots.push({
                  start: beginningAvailable,
                  employeeId: staff.employeeId,
                  assessmentDuration
                });
                beginningAvailable = addMinutes(beginningAvailable, SCHEDULING_SLOT_GAP);
                assessmentEnd = addMinutes(assessmentEnd, SCHEDULING_SLOT_GAP);
              }
            }
          })
        } else {
          // if there are no previously scheduled lessons on the selected date,
          // offer time slots in SCHEDULNG_SLOT_GAP intervals until the start time
          // plus the duration of the lesson is the same as the staff's end time.
          let beginningAvailable = staff.start;
          let assessmentEnd = addMinutes(beginningAvailable, assessmentDuration);
          while(isBefore(assessmentEnd, staff.end) || isEqual(assessmentEnd, staff.end)) {
            availableAssessmentSlots.push({
              start: beginningAvailable,
              employeeId: staff.employeeId,
              assessmentDuration
            });
            assessmentEnd = addMinutes(assessmentEnd, SCHEDULING_SLOT_GAP);
          }
        }
      })
    }
    return availableAssessmentSlots;
  }

  useEffect(() => {
    const getAssessmentAvailabilities = async () => {
      const {success, message, data} = await getAssessmentAvailability(assessmentId);
      if (success) {
        setAssessmentAvailabilities(data);
        setShouldShowLoading(false);
      }
      else {
        setError(message);
        setShouldShowLoading(false);
      }
    }
    getAssessmentAvailabilities();
  }, []);

  useEffect(() => {
    if(assessmentAvailabilities)
      setAvailability(getAvailabilities(selectedDate));
  }, [assessmentAvailabilities, selectedDate])

  return (
    <Container component="main" maxWidth="md">
      <Box>
        <Typography component="h1" variant="h4" align="center">
          Schedule Assessment
        </Typography>
        <Grid container direction="row">
          <Grid item xs container direction="column">
            <Calendar onClickDay={(value) => setSelectedDate(value)} minDate={new Date()} value={selectedDate} />
          </Grid>
          <Grid item xs container direction="column">
            {error && <Typography sx={{ color: 'red' }}>
              {error}
            </Typography>}
          {shouldShowLoading
            ? <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : availability?.length > 0
              ? availability.map(elem => generateAvailabilityCard(elem))
              : (
                <Card>
                  <CardContent>
                    <Typography>No availability on this date</Typography>
                  </CardContent>
                </Card>
          )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Scheduler;