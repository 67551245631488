import axios from 'axios';
import { baseUrl } from '../util';

export const createQuickRegistration = async (registration) => {
  const token = localStorage.getItem('bearer');
  try {
    const regResponse = await axios({
      method: 'post',
      url: `${baseUrl}/auth/quick-reg`,
      data: JSON.stringify(registration),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if(regResponse.status === 200) {
      return {
        success: true,
        message: 'Success',
        data: regResponse.data
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    if(error.response.status === 422) {
      return {
        success: false,
        message: 'This email has already been registered.',
        data: null
      }
    }
    // any other status code
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: null
    }
  }
}

export const getAllParents = async () => {
  try {
    const parentsResponse = await axios({
      method: 'get',
      url: `${baseUrl}/parents`,
    });
    if(parentsResponse.status === 200) {
      return {
        success: true,
        message: 'Success',
        data: parentsResponse.data
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    if(error.response.status === 422) {
      return {
        success: false,
        message: 'This email has already been registered.',
        data: null
      }
    }
    // any other status code
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: null
    }
  }
}

export const getServices = async () => {
  try {
    const servicesResponse = await axios({
      method: "get",
      url: `${baseUrl}/assessments/services`,
    });
    if (servicesResponse.status === 200) {
      return {
        success: true,
        message: "Success",
        data: servicesResponse.data,
      };
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again later.",
        data: null,
      };
    }
  } catch (error) {
    // any other status code
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
      data: null,
    };
  }
};

export const createAssessment = async (
  token,
  parentID,
  studentID,
  serviceID,
  assessmentType
) => {
  const assessment = {
    parentID: parseInt(parentID),
    studentID: parseInt(studentID),
    serviceID,
    assessmentType,
  };
  try {
    const response = await axios({
      method: "post",
      url: `${baseUrl}/assessments`,
      data: JSON.stringify(assessment),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return {
        success: true,
        message: "Success",
        data: response.data,
      };
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again later.",
        data: null,
      };
    }
  } catch (error) {
    // any other status code
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
      data: null,
    };
  }
};

export const getFamilyById = async (familyId) => {
  try {
    const familyResponse = await axios({
      method: 'get',
      url: `${baseUrl}/parents/${familyId}`
    });

    if (familyResponse.status === 200) {
      return {
        success: true,
        message: "Success",
        data: familyResponse.data,
      };
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again later.",
        data: null,
      };
    }
  } catch (error) {
    // any other status code
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
      data: null,
    };
  }
}

export const getAllAssessments = async () => {
  try {
    const getAssessmentsResponse = await axios({
      method: 'get',
      url: `${baseUrl}/assessments/services`
    });
    if(getAssessmentsResponse.status === 200) {
      return {
        success: true,
        message: 'Success',
        data: getAssessmentsResponse.data
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    // any other status code
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: null
    }
  }
}

export const createStudentOnParent = async (parentId, firstName, lastName) => {
  const token = localStorage.getItem('bearer');
  const data = {
    firstName,
    lastName
  };
  try {
    const parentsResponse = await axios({
      method: 'post',
      url: `${baseUrl}/parents/${parentId}/students`,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    if(parentsResponse.status === 200) {
      return {
        success: true,
        message: 'Success',
        data: parentsResponse.data
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    if(error.response.status === 422) {
      return {
        success: false,
        message: 'This student has already been registered.',
        data: null
      }
    }
    // any other status code
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: null
    }
  }
}