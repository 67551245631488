import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel, MenuItem, Radio,
  RadioGroup
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import {createEnrollment} from "../services/dashboard/DashboardService";
import LogoHeader from "../components/LogoHeader";

const Enroll = () => {
  const [enrollSubmitError, setEnrollSubmitError] = useState('');
  const [errors, setErrors] = useState({});
  const {enrollToken} = useParams();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    studentFirstName: yup
      .string('Enter your first name')
      .required('First name is required'),
    studentLastName: yup
      .string('Enter your last name')
      .required('Last name is required'),
    dateOfBirth: yup
      .string('Enter your date of birth')
      .required('Date of birth is required'),
    gender: yup
      .string('Enter your gender')
      .required('Gender is required'),
    school: yup
      .string('Enter your school')
      .required('School is required'),
    grade: yup
      .string('Enter your grade')
      .required('Grade is required'),
    ethnicity: yup
      .string('Enter your ethnicity')
      .required('Ethnicity is required'),
    language: yup
      .string('Enter your language')
      .required('Language is required'),
    primaryFirstName: yup
      .string('Enter your first name')
      .required('First name is required'),
    primaryLastName: yup
      .string('Enter your last name')
      .required('Last name is required'),
    primaryEmail: yup
      .string('Enter your email')
      .required('Email is required'),
    primaryPhone: yup
      .string('Enter your phone number')
      .min(10, 'Please include your area code')
      .required('Phone number is required'),
    primaryCounty: yup
      .string('Enter your county')
      .required('County is required'),
    primaryAddress1: yup
      .string('Enter your street')
      .required('Street is required'),
    primaryCity: yup
      .string('Enter your city')
      .required('City is required'),
    primaryState: yup
      .string('Enter your state')
      .min(2, 'State must be the two letter abbreviation').max(2, 'State must be the two letter abbreviation')
      .required('State is required'),
    primaryZip: yup
      .string('Enter your zipcode')
      .required('Zipcode is required'),
    password: yup
      .string('Enter a password')
      .required('You must enter a password'),
    passwordConfirmation: yup
      .string('You must confirm your password')
      .required('You must confirm your password'),
    consent: yup
      .string('Please agree to the terms')
      .required('You must agree to submit the form')
  });

  const validate = (values) => {
    const validationErrors = {};
    if(values.password !== values.passwordConfirmation) {
      validationErrors.password = true;
      validationErrors.passwordConfirmation = true;
    }
    if(
      !values.supportNeededAdvocacy &&
      !values.supportNeededDiagnosis &&
      !values.supportNeededIntervention &&
      !values.supportNeededVirtual &&
      !values.supportNeededOther
    ) {
      validationErrors.supportNeededOther = true;
    }
    setErrors(validationErrors);
  }

  const formik = useFormik({
    initialValues: {
      supportNeededIntervention: false,
      supportNeededVirtual: false,
      supportNeededDiagnosis: false,
      supportNeededAdvocacy: false,
      supportNeededOther: false,
      studentFirstName: '',
      studentLastName: '',
      dateOfBirth: '',
      gender: '',
      nickname: '',
      school: '',
      grade: '',
      otherSchool: '',
      ethnicity: '',
      otherEthnicity: '',
      language: '',
      otherLanguage: '',
      referredBy: '',
      primaryPrefix: '',
      primaryFirstName: '',
      primaryLastName: '',
      primarySuffix: '',
      primaryEmail: '',
      primaryPhone: '',
      primaryOtherPhone: '',
      primaryCounty: '',
      primaryAddress1: '',
      primaryAddress2: '',
      primaryCity: '',
      primaryState: '',
      primaryZip: '',
      primaryEmployer: '',
      primaryWorkPhone: '',
      secondaryPrefix: '',
      secondaryFirstName: '',
      secondaryLastName: '',
      secondarySuffix: '',
      secondaryEmail: '',
      secondaryPhone: '',
      secondaryOtherPhone: '',
      secondaryCounty: '',
      secondaryAddress1: '',
      secondaryAddress2: '',
      secondaryCity: '',
      secondaryState: '',
      secondaryZip: '',
      secondaryEmployer: '',
      secondaryWorkPhone: '',
      password: '',
      passwordConfirmation: '',
      consent: false
    },
    validationSchema: validationSchema,
    validate: validate,
    onSubmit: async (values) => {
      let supportNeededArray = [];
      if(values.supportNeededAdvocacy) supportNeededArray.push("Advocacy");
      if(values.supportNeededOther) supportNeededArray.push("Other");
      if(values.supportNeededVirtual) supportNeededArray.push("Virtual / Online Tutoring");
      if(values.supportNeededIntervention) supportNeededArray.push("Intervention / Tutoring");
      if(values.supportNeededDiagnosis) supportNeededArray.push("Diagnosis / Academic Assessment");
      const valuesWithSupportNeeded = {
        ...values,
        supportNeeded: supportNeededArray.join(", ")
      }
      const { success, message } = await createEnrollment(enrollToken, valuesWithSupportNeeded);
      if(success) {
        navigate("/");
      } else {
        setEnrollSubmitError(message);
        setTimeout(() => setEnrollSubmitError(''), 5000);
      }
    },
  });

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ pt: "10px" }}>
        <LogoHeader />
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4">
          Learning Matters Enrollment Form
        </Typography>
        <Typography component="p" sx={{ fontSize: "14px" }}>
          Please contact us at (615) 739-0547 if you are unable to fill out this
          form online.
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Registration Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="p"
                variant="p"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Academic Support Needed *
              </Typography>
              <FormGroup>
                <InputLabel>Please select all that apply</InputLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="supportNeededIntervention"
                      value={formik.values.supportNeededIntervention}
                      onChange={formik.handleChange}
                      id="Intervention / Tutoring"
                    />
                  }
                  label="Intervention / Tutoring"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="supportNeededVirtual"
                      value={formik.values.supportNeededVirtual}
                      onChange={formik.handleChange}
                      id="Virtual / Online Tutoring"
                    />
                  }
                  label="Virtual / Online Tutoring"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="supportNeededDiagnosis"
                      value={formik.values.supportNeededDiagnosis}
                      onChange={formik.handleChange}
                      id="Diagnosis / Academic Assessment"
                    />
                  }
                  label="Diagnosis / Academic Assessment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="supportNeededAdvocacy"
                      value={formik.values.supportNeededAdvocacy}
                      onChange={formik.handleChange}
                      id="Advocacy"
                    />
                  }
                  label="Advocacy"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="supportNeededOther"
                      value={formik.values.supportNeededOther}
                      onChange={formik.handleChange}
                      id="Other"
                    />
                  }
                  label="Other"
                />
              </FormGroup>
              {errors.supportNeededOther && (
                <div style={{ color: "#d32f2f" }}>
                  You must select at least one Academic Support
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Student Information</Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                required
                fullWidth
                id="studentFirstName"
                label="First Name"
                name="studentFirstName"
                value={formik.values.studentFirstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.studentFirstName &&
                  Boolean(formik.errors.studentFirstName)
                }
                helperText={
                  formik.touched.studentFirstName &&
                  formik.errors.studentFirstName
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                required
                fullWidth
                name="studentLastName"
                label="Last Name"
                id="studentLastName"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="date"
                name="dateOfBirth"
                label="Date of Birth"
                id="dateOfBirth"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="gender">Gender *</FormLabel>
                <RadioGroup aria-labelledby="gender" name="gender">
                  <FormControlLabel
                    name="gender"
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    name="gender"
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    name="gender"
                    value="other"
                    control={<Radio />}
                    label="Prefer not to say"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="nickname"
                label="Goes by / Nickname"
                id="nickname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                name="school"
                label="Current School"
                id="school"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="Abintra Montessori">
                  Abintra Montessori
                </MenuItem>
                <MenuItem value="Akiva">Akiva</MenuItem>
                <MenuItem value="Battleground Academy / BA">
                  Battleground Academy / BA
                </MenuItem>
                <MenuItem value="Bellevue Middle">Bellevue Middle</MenuItem>
                <MenuItem value="Belmont University">
                  Belmont University
                </MenuItem>
                <MenuItem value="Belmont Waverly Elementary">
                  Belmont Waverly Elementary
                </MenuItem>
                <MenuItem value="Benton Hall">Benton Hall</MenuItem>
                <MenuItem value="Brentwood Academy">Brentwood Academy</MenuItem>
                <MenuItem value="Cane Ridge Elementary">
                  Cane Ridge Elementary
                </MenuItem>
                <MenuItem value="Carter Lawrence Elementary">
                  Carter Lawrence Elementary
                </MenuItem>
                <MenuItem value="Christ Presbyterian Academy / CPA">
                  Christ Presbyterian Academy / CPA
                </MenuItem>
                <MenuItem value="Christ the King">Christ the King</MenuItem>
                <MenuItem value="Clarksville Academy">
                  Clarksville Academy
                </MenuItem>
                <MenuItem value="Connersville">Connersville</MenuItem>
                <MenuItem value="Cornersville">Cornersville</MenuItem>
                <MenuItem value="Covenant School">Covenant School</MenuItem>
                <MenuItem value="Creive Hall Elementary">
                  Creive Hall Elementary
                </MenuItem>
                <MenuItem value="Crockett Elementary">
                  Crockett Elementary
                </MenuItem>
                <MenuItem value="Curry Ingram Academy">
                  Curry Ingram Academy
                </MenuItem>
                <MenuItem value="Davidson Academy">Davidson Academy</MenuItem>
                <MenuItem value="Dickson">Dickson</MenuItem>
                <MenuItem value="Donelson Christian Academy / DCA">
                  Donelson Christian Academy / DCA
                </MenuItem>
                <MenuItem value="Eakin Elementary">Eakin Elementary</MenuItem>
                <MenuItem value="East End Elementary">
                  East End Elementary
                </MenuItem>
                <MenuItem value="East End Prep.">East End Prep.</MenuItem>
                <MenuItem value="Edmondson Elementary">
                  Edmondson Elementary
                </MenuItem>
                <MenuItem value="Emory">Emory</MenuItem>
                <MenuItem value="Ensworth">Ensworth</MenuItem>
                <MenuItem value="Fairview High School">
                  Fairview High School
                </MenuItem>
                <MenuItem value="Father Ryan">Father Ryan</MenuItem>
                <MenuItem value="Franklin Elementary">
                  Franklin Elementary
                </MenuItem>
                <MenuItem value="Franklin Road Academy / FRA">
                  Franklin Road Academy / FRA
                </MenuItem>
                <MenuItem value="Glendale Elementary">
                  Glendale Elementary
                </MenuItem>
                <MenuItem value="Glenview Elementary">
                  Glenview Elementary
                </MenuItem>
                <MenuItem value="Granbery Elementary">
                  Granbery Elementary
                </MenuItem>
                <MenuItem value="Grassland Elementary">
                  Grassland Elementary
                </MenuItem>
                <MenuItem value="Harding Academy">Harding Academy</MenuItem>
                <MenuItem value="Harpeth Hall">Harpeth Hall</MenuItem>
                <MenuItem value="Head Middle Magnet">
                  Head Middle Magnet
                </MenuItem>
                <MenuItem value="Hendersonville Montessori">
                  Hendersonville Montessori
                </MenuItem>
                <MenuItem value="Heritage-White House">
                  Heritage-White House
                </MenuItem>
                <MenuItem value="Hickman Elementary">
                  Hickman Elementary
                </MenuItem>
                <MenuItem value="Hillsboro High School">
                  Hillsboro High School
                </MenuItem>
                <MenuItem value="Hillsboro Middle">Hillsboro Middle</MenuItem>
                <MenuItem value="Hillwood High">Hillwood High</MenuItem>
                <MenuItem value="Holy Rosary Montessori">
                  Holy Rosary Montessori
                </MenuItem>
                <MenuItem value="Holy Trinity Montessori">
                  Holy Trinity Montessori
                </MenuItem>
                <MenuItem value="Hume Fogg">Hume Fogg</MenuItem>
                <MenuItem value="J.T. Moore">J.T. Moore</MenuItem>
                <MenuItem value="JFK Middle">JFK Middle</MenuItem>
                <MenuItem value="John Paul II">John Paul II</MenuItem>
                <MenuItem value="Jones Paideia Elementary">
                  Jones Paideia Elementary
                </MenuItem>
                <MenuItem value="Julia Green Elementary">
                  Julia Green Elementary
                </MenuItem>
                <MenuItem value="Kipp Nashville College">
                  Kipp Nashville College
                </MenuItem>
                <MenuItem value="Lead Academy">Lead Academy</MenuItem>
                <MenuItem value="Lighthouse Christian">
                  Lighthouse Christian
                </MenuItem>
                <MenuItem value="Linden Waldorf">Linden Waldorf</MenuItem>
                <MenuItem value="Lipscomb Academy">Lipscomb Academy</MenuItem>
                <MenuItem value="Lockeland Elementary">
                  Lockeland Elementary
                </MenuItem>
                <MenuItem value="Madison Creek Elementary">
                  Madison Creek Elementary
                </MenuItem>
                <MenuItem value="Maplewood High">Maplewood High</MenuItem>
                <MenuItem value="Martin Elementary">Martin Elementary</MenuItem>
                <MenuItem value="McKissick Middle">McKissick Middle</MenuItem>
                <MenuItem value="Meigs ">Meigs </MenuItem>
                <MenuItem value="Middle Tennessee Christian">
                  Middle Tennessee Christian
                </MenuItem>
                <MenuItem value="MLK">MLK</MenuItem>
                <MenuItem value="Montgomery Bell Academy / MBA">
                  Montgomery Bell Academy / MBA
                </MenuItem>
                <MenuItem value="Mt. Juliet Christian Academy">
                  Mt. Juliet Christian Academy
                </MenuItem>
                <MenuItem value="Mt. Juliet Middle">Mt. Juliet Middle</MenuItem>
                <MenuItem value="Nashville Christian">
                  Nashville Christian
                </MenuItem>
                <MenuItem value="Nashville School of the Arts / NSA">
                  Nashville School of the Arts / NSA
                </MenuItem>
                <MenuItem value="New Hope Academy">New Hope Academy</MenuItem>
                <MenuItem value="Oak Hill School">Oak Hill School</MenuItem>
                <MenuItem value="Overbrook School">Overbrook School</MenuItem>
                <MenuItem value="John Overton High School">
                  John Overton High School
                </MenuItem>
                <MenuItem value="Pearre Creek Elementary">
                  Pearre Creek Elementary
                </MenuItem>
                <MenuItem value="Pegram Elementary">Pegram Elementary</MenuItem>
                <MenuItem value="Percy Priest Elementary">
                  Percy Priest Elementary
                </MenuItem>
                <MenuItem value="Poplar Grove">Poplar Grove</MenuItem>
                <MenuItem value="Rutland Elementary">
                  Rutland Elementary
                </MenuItem>
                <MenuItem value="Scales Elementary">Scales Elementary</MenuItem>
                <MenuItem value="Shane Elementary">Shane Elementary</MenuItem>
                <MenuItem value="St. Matthew's">St. Matthew's</MenuItem>
                <MenuItem value="St. Bernard Academy / SBA">
                  St. Bernard Academy / SBA
                </MenuItem>
                <MenuItem value="St. Cecelia">St. Cecelia</MenuItem>
                <MenuItem value="St. Edwards">St. Edwards</MenuItem>
                <MenuItem value="St. Henry School">St. Henry School</MenuItem>
                <MenuItem value="St. Paul Christian">
                  St. Paul Christian
                </MenuItem>
                <MenuItem value="St. Rose Catholic">St. Rose Catholic</MenuItem>
                <MenuItem value="Station Camp Elementary">
                  Station Camp Elementary
                </MenuItem>
                <MenuItem value="Stewarts Creek Middle">
                  Stewarts Creek Middle
                </MenuItem>
                <MenuItem value="Texas Christian University">
                  Texas Christian University
                </MenuItem>
                <MenuItem value="Thurgood Marshall">Thurgood Marshall</MenuItem>
                <MenuItem value="University of Alabama">
                  University of Alabama
                </MenuItem>
                <MenuItem value="University School of Nashville / USN">
                  University School of Nashville / USN
                </MenuItem>
                <MenuItem value="Valor Collegiate Academy">
                  Valor Collegiate Academy
                </MenuItem>
                <MenuItem value="Walnut Grove">Walnut Grove</MenuItem>
                <MenuItem value="West End Middle">West End Middle</MenuItem>
                <MenuItem value="West Wilson Middle">
                  West Wilson Middle
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="Homeschool">Homeschool</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                name="grade"
                label="Current Grade"
                id="grade"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="otherSchool"
                label="If you selected other, please indicate current school."
                id="otherSchool"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                name="ethnicity"
                label="Ethnicity"
                id="ethnicity"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="Caucasian">Caucasian</MenuItem>
                <MenuItem value="Latin American / Hispanic">
                  Latin American / Hispanic
                </MenuItem>
                <MenuItem value="Black / African American">
                  Black / African American
                </MenuItem>
                <MenuItem value="Native American / American Indian">
                  Native American / American Indian
                </MenuItem>
                <MenuItem value="Asian / Pacific Islander">
                  Asian / Pacific Islander
                </MenuItem>
                <MenuItem value="Middle Eastern">Middle Eastern</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="otherEthnicity"
                label="If you selected other, please specify ethnicity."
                id="otherEthnicity"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                name="language"
                label="Language spoken at home"
                id="language"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="otherLanguage"
                label="If you selected other, please specify language."
                id="otherLanguage"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Primary Parent / Guardian Contact Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="referredBy"
                label="Referred by"
                id="referredBy"
                helperText="How did you hear about Learning Matters?"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="primaryPrefix"
                label="Prefix"
                id="primaryPrefix"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                required
                fullWidth
                name="primaryFirstName"
                label="First Name"
                id="primaryFirstName"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                required
                name="primaryLastName"
                label="Last Name"
                id="primaryLastName"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="primarySuffix"
                label="Suffix"
                id="primarySuffix"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type={"email"}
                name="primaryEmail"
                label="Email"
                id="primaryEmail"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type={"tel"}
                name="primaryPhone"
                label="Mobile / Preferred Phone"
                id="primaryPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"tel"}
                name="primaryOtherPhone"
                label="Other phone"
                id="primaryOtherPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                name="primaryCounty"
                label="County"
                id="primaryCounty"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="Cheatham">Cheatham</MenuItem>
                <MenuItem value="Davidson">Davidson</MenuItem>
                <MenuItem value="Dickson">Dickson</MenuItem>
                <MenuItem value="Giles">Giles</MenuItem>
                <MenuItem value="Robertson">Robertson</MenuItem>
                <MenuItem value="Rutherford">Rutherford</MenuItem>
                <MenuItem value="Sumner">Sumner</MenuItem>
                <MenuItem value="Williamson">Williamson</MenuItem>
                <MenuItem value="Wilson">Wilson</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="primaryAddress1"
                label="Address Line 1"
                id="primaryAddress1"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="primaryAddress2"
                label="Address Line 2"
                id="primaryAddress2"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                required
                fullWidth
                name="primaryCity"
                label="City"
                id="primaryCity"
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <TextField
                required
                fullWidth
                name="primaryState"
                label="State"
                id="primaryState"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                required
                fullWidth
                name="primaryZip"
                label="Zip/Postal Code"
                id="primaryZip"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="primaryEmployer"
                label="Employer"
                id="primaryEmployer"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"tel"}
                name="primaryWorkPhone"
                label="Work phone"
                id="primaryWorkPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Secondary Parent / Guardian Contact Information
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="secondaryPrefix"
                label="Prefix"
                id="secondaryPrefix"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="secondaryFirstName"
                label="First Name"
                id="secondaryFirstName"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="secondaryLastName"
                label="Last Name"
                id="secondaryLastName"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                fullWidth
                name="secondarySuffix"
                label="Suffix"
                id="secondarySuffix"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"email"}
                name="secondaryEmail"
                label="Email"
                id="secondaryEmail"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"tel"}
                name="secondaryPhone"
                label="Mobile / Preferred Phone"
                id="secondaryPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"tel"}
                name="secondaryOtherPhone"
                label="Other phone"
                id="secondaryOtherPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                name="secondaryCounty"
                label="County"
                id="secondaryCounty"
              >
                <MenuItem value={""}>Select...</MenuItem>
                <MenuItem value="Cheatham">Cheatham</MenuItem>
                <MenuItem value="Davidson">Davidson</MenuItem>
                <MenuItem value="Dickson">Dickson</MenuItem>
                <MenuItem value="Giles">Giles</MenuItem>
                <MenuItem value="Robertson">Robertson</MenuItem>
                <MenuItem value="Rutherford">Rutherford</MenuItem>
                <MenuItem value="Sumner">Sumner</MenuItem>
                <MenuItem value="Williamson">Williamson</MenuItem>
                <MenuItem value="Wilson">Wilson</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="secondaryAddress1"
                label="Address Line 1"
                id="secondaryAddress1"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="secondaryAddress2"
                label="Address Line 2"
                id="secondaryAddress2"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                name="secondaryCity"
                label="City"
                id="secondaryCity"
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <TextField
                fullWidth
                name="secondaryState"
                label="State"
                id="secondaryState"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                name="secondaryZip"
                label="Zip/Postal Code"
                id="secondaryZip"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="secondaryEmployer"
                label="Employer"
                id="secondaryEmployer"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type={"tel"}
                name="secondaryWorkPhone"
                label="Work phone"
                id="secondaryWorkPhone"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Set Your Password</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              {errors.password && (
                <div style={{ color: "#d32f2f" }}>Passwords must match</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                id="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
              />
              {errors.passwordConfirmation && (
                <div style={{ color: "#d32f2f" }}>Passwords must match</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Terms and Conditions</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                We are a nonprofit organization. This policy was written in an
                attempt to fairly serve our clients while providing our teachers
                with a stable income. Please feel free to contact us at any time
                to discuss special circumstances. We thank you in advance for
                adhering to the policy.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" sx={{ fontWeight: "bold" }}>
                Tutoring Payment and Scheduling Policy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                If your family has a combined income of less than $75,000 a
                year, you will qualify for a reduced rate, according to our
                sliding scale fee schedule, when a copy of your most recent tax
                return is submitted to us. Client invoices will be prepared on
                the 15th of each month for continuing students. The invoices
                will include sessions that have been scheduled for the following
                month. Should a client need to make any changes to the schedule
                that are included on the invoice, they are asked to call
                Learning Matters before the first day of the month to report
                those changes. Dates will be rearranged or credits will be
                given.{" "}
                <strong>Payment is due by the first day of the month.</strong>{" "}
                If you pay by credit card on file, your credit card will be
                charged on the first of the month, unless other arrangements are
                made.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" sx={{ fontWeight: "bold" }}>
                Cancellation Policy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                <strong>
                  Cancellations require a 24–hour notice. Make–up dates will be
                  offered to the client to be used within 60 days. No credits or
                  refunds will be given.
                </strong>
                Should the teacher need to cancel a session, the client will be
                offered either a make–up session or a credit. If a client fails
                to appear for a scheduled session, they will be charged for that
                session because the teacher will be paid for their time and
                preparation. Should tutoring be discontinued, any remaining
                balance will be offered in the form of tutoring sessions, but
                must be used within 90 days.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" sx={{ fontWeight: "bold" }}>
                Assessment and Advocacy Payment and Scheduling Policy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                If your family has a combined income of less than $75,000 a
                year, you will qualify for a reduced rate according to our
                sliding scale fee schedule when a copy of your most recent tax
                return is submitted to us. An upfront deposit of $300 is
                required to secure your psycho-educational evaluation. A $150
                deposit is required to secure your diagnostic evaluation. At
                least 24-hour notice is required to reschedule an assessment
                without losing the deposit.{" "}
                <strong>Payment is due at the time service is rendered.</strong>{" "}
                Payment may be made by credit card or check.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" sx={{ fontWeight: "bold" }}>
                Collection of Overdue Accounts
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                Clients are asked to maintain a zero balance at all times. If
                collection procedures become necessary for an overdue account,
                you will be required to pay reasonable attorneyʼs fees and court
                costs.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="p" sx={{ fontWeight: "bold" }}>
                Consent and Authorization *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                required
                name="consent"
                control={<Checkbox />}
                label="As the client (or Parent/Guardian), I have read, understand, and agree to the terms and information above."
              />
            </Grid>
            {enrollSubmitError && (
              <Grid item xs={12}>
                <Typography variant="subtitle" color={"red"}>
                  {enrollSubmitError}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Enroll
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Enroll;