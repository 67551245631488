import axios from 'axios';
import { baseUrl } from '../util';

export const createEnrollment = async (enrollToken, requestObject) => {
  try {
    const enrollResponse = await axios({
      method: 'post',
      url: `${baseUrl}/auth/enroll`,
      data: JSON.stringify(requestObject),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${enrollToken}`
      }
    });
    if(enrollResponse.status === 200) {
      localStorage.setItem('bearer', enrollResponse.data.token);
      return {
        success: true,
        message: 'Success',
        data: enrollResponse.data
      };
    } else {
      return {
        success: false,
        message: 'Something went wrong. Please try again later.',
        data: null
      }
    }
  }
  catch(error) {
    return {
      success: false,
      message: 'Something went wrong. Please try again later.',
      data: error
    }
  }
}

export const getAssessmentAvailability = async (tempID) => {
  const bearerToken = localStorage.getItem('bearer');
  try {
    const availabilityResponse = await axios({
      method: 'get',
      url: `${baseUrl}/assessments/${tempID}/availability`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`
      }
    });
    if(availabilityResponse.status === 200) {
      return {
        success: true,
        message: "Success",
        data: availabilityResponse.data
      };
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again later.",
        data: null,
      };
    }
  } catch (error) {
    // any other status code
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
      data: null,
    };
  }
}

export const createAssessment = async (tempID, scheduleDate, teacherID) => {
  const bearerToken = localStorage.getItem('bearer');
  try {
    const createAssessmentResponse = await axios({
      method: 'post',
      url: `${baseUrl}/assessments/${tempID}/schedule`,
      data: JSON.stringify({scheduleDate, teacherID}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`
      }
    });
    if(createAssessmentResponse.status === 200) {
      return {
        success: true,
        message: "Success",
        data: createAssessmentResponse.data
      };
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again later.",
        data: null,
      };
    }
  } catch (error) {
    // any other status code
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
      data: null,
    };
  }
}