import {Box, Button, Grid, Typography} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AssessmentCard from "./AssessmentCard";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

const ChildContainer = ({isAdminView, student}) => {
  const navigate = useNavigate();

  const goToAddAssessment = (event, studentId) => {
    event.preventDefault();
    navigate(`student/${studentId}/add-assessment`);
  }

  if(!student) {
    return (<Paper elevation={6}>
      <Box>
        <Card variant="outlined">
          <CardContent>
            <Grid container direction="column">
              <Grid item xs container direction="column">
                <Typography sx={{ fontWeight: 'bold' }} align="center">You have no students registered currently</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Paper>)
  }

  return (
    <Paper key={student.id} elevation={6} sx={{ px: "2rem", pt: "1rem", mb: "2rem", pb: "2rem" }}>
      <Box sx={{ display: "flex" }}>
        <Box flex={1} />
        <Typography flex={1} component="h2" variant="h4" align="center" mb={"0.5rem"}>
          {student.first_name} {student.last_name}
        </Typography>
        <Box flex={1} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          {isAdminView && <Button variant="text" size="large" endIcon={<AddCircleRoundedIcon />} onClick={(e) => goToAddAssessment(e, student.id)}>Add assessment</Button>}
        </Box>
      </Box>
      {student?.assessments?.map(service => <AssessmentCard key={service.serviceID} isAdminView={isAdminView} assessment={service}/>)}
    </Paper>
  );
}

export default ChildContainer