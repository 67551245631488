import {Fragment, useContext, useEffect, useState} from "react";
import DashboardContext from "../../services/dashboard/DashboardContext";
import ChildContainer from "../../components/ChildContainer";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";
import {meService} from "../../services/auth/AuthService";

const Parent = () => {
  const {isAdmin, isLoggedIn, isLoggingIn, setShouldShowDashboard, userData} = useContext(DashboardContext);
  const [fullUserData, setFullUserData] = useState(userData);
  const [students, setStudents] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const callMeEndpoint = async () => {
      const bearerToken = localStorage.getItem("bearer");
      if (bearerToken) {
        setShowLoading(true);
        const { success, data } = await meService(bearerToken);
        if (success) {
          setFullUserData(data);
          setShowLoading(false);
        }
      }
    };
    if (isLoggedIn && !isLoggingIn) callMeEndpoint();
  }, [isLoggedIn, isLoggingIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setFullUserData(userData);
      setShowLoading(false);
    }
  }, [isLoggedIn, userData]);

  useEffect(() => {
    const twStudents = fullUserData?.teachworksDetails?.students?.map((st) => {
      st.assessments = [];
      fullUserData?.assessments?.forEach((assessment) => {
        if (assessment.studentID === st.id) {
          if (assessment.tempID) {
            st.assessments.push(assessment);
          } else {
            fullUserData?.teachworksDetails?.assessments?.find(
              (twAssessment) => {
                if (twAssessment.id === assessment.lessonID)
                  st.assessments.push(twAssessment);
              }
            );
          }
        }
      });
      return st;
    });

    setStudents(twStudents);
  }, [fullUserData]);

  const buildStudentCards = () => {
    if(!students?.length) {
      return <ChildContainer isAdminView={isAdmin} student={null} />
    }
    return students.map(student => {
      if(student.status === 'Active') {
        return <ChildContainer key={student.id} isAdminView={isAdmin} student={student}/>
      }
      return null;
    });
  }

  const signOut = () => {
    localStorage.removeItem('bearer');
    setShouldShowDashboard(false);
    navigate('/');
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box>
        <Box sx={{ display: "flex", justifyContent: 'center' }}>
          <Typography component="h1" variant="h4" align="center">
            Dashboard
          </Typography>
        </Box>
        {showLoading
          ? <Box sx={{ display: "flex", justifyContent: 'center', mt: '2rem' }}><CircularProgress /></Box>
          : <Fragment>
              <Box>
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "end" }}>
                  <Typography component="h3" align="right" sx={{ alignSelf: "end"}}>
                    Hello {fullUserData.nameFirst} {fullUserData.nameLast}!
                  </Typography>
                  <Button variant="text" onClick={signOut} sx={{ alignSelf: "end"}}>Sign out</Button>
                </Box>
              </Box>
              {buildStudentCards()}
            </Fragment>
        }
      </Box>
    </Container>
  )
}

export default Parent;